import * as React from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function Calculator() {
  const [toggled, setToggled] = React.useState(false);

  const [sliderValue, setSliderValue] = React.useState(30);

  const [lifeFactorId, setLifeFactorId] = React.useState(1);

  const lifeFactors = [
    {
      id: 1,
      value: 3,
      label: "Puppy 0 - 4 months",
    },
    {
      id: 2,
      value: 2,
      label: "Puppy 4 - 12 months",
    },
    {
      id: 3,
      value: 1.8,
      label: "Intact Adult",
    },
    {
      id: 4,
      value: 1.6,
      label: "Neutered Adult",
    },
    {
      id: 5,
      value: 1.3,
      label: "Inactive/Obese prone Adult",
    },
    {
      id: 6,
      value: 1,
      label: "Weight Loss",
    },
    {
      id: 7,
      value: 1.8,
      label: "Weight Gain",
    },
  ];

  function calCal() {
    const weightInKg = !toggled ? sliderValue / 2.2 : sliderValue;

    const lifeFactorValue = lifeFactors.find(
      (item) => item.id === lifeFactorId
    ).value;

    const cals = weightInKg ** 0.75 * 70 * lifeFactorValue;

    return Math.round(cals);
  }

  return (
    <Card sx={{ minWidth: 275, backgroundColor: "#f2f2f2", padding: "5px", borderRadius: 7 }} >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={<Switch color="primary" />}
                  label="Use kg instead"
                  labelPlacement="end"
                  checked={toggled}
                  onChange={(e) => setToggled(e.target.checked)}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography id="weight-slider" gutterBottom>
                Weight in{" "}
                {toggled === true ? `kg: ${sliderValue}` : `lb: ${sliderValue}`}
              </Typography>
              <Slider
                value={sliderValue}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(e) => setSliderValue(e.target.value)}
                size="large"
                max={140}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TextField
                select
                label="Life Factor"
                helperText="Please select your pups life factor"
                value={lifeFactorId}
                onChange={(e) => setLifeFactorId(e.target.value)}
              >
                {lifeFactors.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: 18,
                color: "#363430",
              }}
            >
              Estimated daily calorie requirement:{" "}
              <Typography sx={{ fontWeight: "bold" }}>{calCal()}</Typography>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
