import * as React from "react";
import "./App.css";
import { Grid, Typography, Button, Link } from "@mui/material";
import Calculator from "../components/Calculator";

export default function Calc() {


  return (
    <div className="App">
      <div className="App-background">
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
            sx={{
              color: "#363430",
            }}
            gutterBottom
            variant="h3"
          >
            Calculator
          </Typography>
          </Grid>
        </Grid>
        <Calculator />
      </div>
    </div>
  );
}
