import * as React from "react";
import { css } from "@emotion/react";
import "./App.css";
import { Grid, Typography, Button, Link } from "@mui/material";
import { Container, Box, width } from "@mui/system";
import {ContactForm} from "../components/ContactForm";



export default function Contact() {
  const imageStyle = {
    maxWidth: "60%",
    height: "auto",
    padding: 0,
    margin: 0,
  };

  return (
    <div className="App">
      <div className="App-background">
        <Container>
          <ContactForm />
        </Container>
      </div>
    </div>
  );
}
