import * as React from "react";
import { css } from "@emotion/react";
import "./App.css";
import { Grid, Typography } from "@mui/material";
import { Container, Box, width } from "@mui/system";
import maxImg from "../assets/max_doc.webp";

export default function About() {
  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    //padding: "10px",
    paddingTop: "5px",
    borderRadius: "30px",
  };

  return (
    <div className="App">
      <div className="App-background">
        <Container>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={10} md={4}>
              <img style={imageStyle} src={maxImg} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#363430",
                }}
                gutterBottom
              >
                This is Max, a very good boy who as a pup was a picky eater. It
                seemed any sort of kibble would upset his stomach, but he loved
                fresh meat, veggies, and rice. While it took us awhile to figure
                out what foods he could tolerate, we always struggled with just
                how much he should be eating. During many such research sessions
                I stumbled on these guidlines created by the{" "}
                <a
                  href="https://vmc.vet.osu.edu/services/companion-animal-nutrition-support-service#calculator"
                  target="_blank"
                >
                  Ohio State University College of Vetrenary Medicine
                </a>
                . We started following the formula outlined by OSU to calculate
                just how many calories we should be feeding Max, but realized it
                can be confusing and complicated to perform the calculations.
                Thats where the idea for the pupcals calorie calculator came
                from. I hope you find it as useful as we have!
                <br />
                <br />
                This tool is to be used at your own risk only. Please review the
                information linked above and always consult your veternarian
                before making any changes.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
