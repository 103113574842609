import * as React from "react";
import { css } from "@emotion/react";
import "./App.css";
import { Grid, Typography, Link } from "@mui/material";
import { Container, Box, width } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";

export default function Home() {
  const imageStyle = {
    maxWidth: "35%",
    height: "auto",
    padding: 0,
    margin: "10px",
    borderRadius: "30px",
  };

  return (
    <div className="App">
      <div className="App-background">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#363430",
                }}
                gutterBottom
                variant="h2"
              >
                Welcome to PupCals
              </Typography>
              <Typography gutterBottom variant="h5">
                We are trying to help dog owners make informed, science based
                decisions regarding how much to feed their pup. With our easy to
                use calculator, you can quickly have reference for how many
                calories they should be recieving from their food each day.
              </Typography>
              <Typography gutterBottom variant="h5">
                You can get started by reading the{" "}
                <Link component={RouterLink} to="/about">
                  About
                </Link>{" "}
                section, or begin using the{" "}
                <Link component={RouterLink} to="/calculator">
                  Calculator
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
