import * as React from "react";
import emailjs from "@emailjs/browser";
import {
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  Alert,
  AlertTitle,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { green } from "@mui/material/colors";

//TODO: Circular Progress for submit button

export const ContactForm = () => {
  const [fromName, setFromName] = React.useState("");
  const [fromEmail, setFromEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  //TODO: validate text inputs for email address better
  const handleSubmit = (e) => {
    if (
      fromName.length > 0 &&
      fromEmail.length > 0 &&
      message.length > 0
    ) {
      emailjs
        .send(
          "service_n3cx83w",
          "template_6s25uqm",
          { from_name: fromName, from_email: fromEmail, message: message },
          "pqSo622XisWrTP9kl"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("Thank you for your message!");
            setFromName("");
            setFromEmail("");
            setMessage("");
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      alert("enter valid values");
    }
  };

  return (
    <Box component="form">
      <FormControl>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#363430",
              }}
              gutterBottom
              variant="h3"
            >
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Please use the below form to send us any comments or conerns you
              have.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Name"
              name="fromName"
              value={fromName}
              fullWidth
              required
              onChange={(e) => setFromName(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              name="fromEmail"
              value={fromEmail}
              type="email"
              fullWidth
              required
              onChange={(e) => setFromEmail(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              minRows={5}
              fullWidth
              required
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  );
};
